<template>
  <div>
    <div style="margin: 20px 0 0 20px">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <div class="manage-list">
      <!-- <div class="manage-list-top">
        <div class="manage-list-top-cont">
          <div class="active">待审核</div>
          <div>待定</div>
        </div>
      </div> -->
      <div class="search">
        <div class="search-item">
          <p>状态：</p>
          <div class="status-list">
            <div class="status-item" :class="item.id===miniProStatus?'active-item':''" v-for="item in statusArr" @click="statusChange(item.id)" :key="item.id">{{ item.name }}</div>
          </div>
        </div>
        <Input v-model="keyword"
          :placeholder="$t('project_search_project_nameid')"
          clearable
          search
          :enter-button="true"
          @on-enter="changeKeyword"
          @on-search="changeKeyword"
          class="list-search-r-input"
        />
      </div>
      <div class="table">
        <Table border :columns="columns" :loading="loading" :data="dataList"></Table>
      </div>
      <div class="page">
        <Page :total="total" @on-change="changePage" @on-page-size-change="changePageSize" :current="page" show-total show-sizer show-elevator></Page>
      </div>
      <Modal
          v-model="verifyModal"
          title="审核失败原因"
          @on-ok="okVerifyModal"
          @on-cancel="cancelVerifyModal">
        <div class="verify-reason">
          <p>审核失败原因:</p>
          <Input class="verify-reason-input" v-model="verifyReason" type="textarea" placeholder="请输入审核失败原因" />
        </div>
      </Modal>
      <Modal
          v-model="publishModal"
          title="发布"
      >
        <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="120">
          <FormItem label="小程序名称" prop="name">
            <Input v-model="formValidate.name" placeholder="请输入小程序名称"></Input>
          </FormItem>
          <FormItem label="上传小程序二维码" prop="appQr">
            <Upload :action="constant.URL + '/uploadfile/upload'"
                    :data="{
                      type:3
                    }"
                    accept=".jpg,.png,.jpeg,.gif"
                    :headers="{
                      'access-token':Cookies.get(constant.tokenName)
                    }"
                    :on-success="fileSuccess"
                    :show-upload-list="false"
            >
              <Button v-if="!formValidate.appQr" icon="ios-cloud-upload-outline">上传小程序二维码</Button>
              <img v-else :src="formValidate.appQr" class="wx-code" />
            </Upload>
          </FormItem>

        </Form>
        <div slot="footer">
          <Button @click="cancelPublishModal" style="margin-left: 8px">取消</Button>
          <Button type="primary" @click="okPublishModal">确定</Button>
        </div>
      </Modal>
    </div>
  </div>

</template>

<script>
import util from '@/utils/tools.js';
import Cookies from "js-cookie";
export default {
  name: "managelist.vue",
  data(){
    return {
      Cookies:Cookies,
      columns: [
        // {
        //   title: '序号',
        //   key:'id',
        //   width: 65
        // },
        {
          title: '渠道名称',
          key:'appid',
          render: (h, para) => {
            return h('span', para.row?.site?.name + '('+para.row?.site?.id+')');
          }
        },
        {
          title: '小程序名称',
          key:'name',
        },
        {
          title: 'AppID',
          key:'appid',
          width:160,
        },
        {
          title: 'AppSecret',
          key:'appsecret',
          width:100,
          render: (h, para) => {
            return h('span', para.row.appsecret ? para.row.appsecret.slice(0,3) + '****' : '');
          }
        },
        {
          title: '微信号',
          key:'appsecret',
          render: (h, para) => {
            return h('span', para.row?.site?.contact_wx);
          }
        },
        {
          title: '手机号',
          key:'appsecret',
          render: (h, para) => {
            return h('span', para.row?.site?.contact_mobile);
          }
        },
        {
          title: '客服微信二维码',
          key:'appsecret',
          width:120,
          render: (h, para) => {
            return para.row?.site?.contact_wx_qrcode ? h('img',{
              style: {//设置样式
                width: '80px',
                'height': '80px',
                'border-radius': '5%'
              },
              attrs:{
                src:para.row.site.contact_wx_qrcode
              }
            }, '') : '';
          }
        },
        // {
        //   title: '授权小程序',
        //   key:'id',
        // },
        {
          title: '提交时间',
          key:'created_at',
          width:105,
          render: (h, para) => {
            return h('span', util.timeFormatter(
                    new Date(+para.row.created_at * 1000),
                    'yyyy-MM-dd hh:mm'
                )
            );
          }
        },
        {
          title: '更新时间',
          key:'updated_at',
          width:105,
          render: (h, para) => {
            return h('span', util.timeFormatter(
                    new Date(+para.row.updated_at * 1000),
                    'yyyy-MM-dd hh:mm'
                )
            );
          }
        },
        {
          title: '状态',
          key:'status',
          width:90,
          render: (h, para) => {
            return h('span',this.statusObj && this.statusObj[para.row.status]);
          }
        },
        {
          title: '操作',
          render: (h, params) => {
            return h('div', [
              h("Button",{
                props:{
                  type:"primary",
                  size:"small"
                },
                style:{
                  marginRight:"5px",
                  marginBottom:"5px"
                },
                on:{
                  click:()=>{
                    this.edit(params.row);
                  }
                }
              },"编辑"),
              // params.row.status == 1 ? h('Button', {
              //   props: {
              //     type: 'primary',
              //     size: 'small',
              //   },
              //   style: {
              //     marginRight: '5px',
              //     marginBottom:"5px"
              //   },
              //   on: {
              //     click: () => {
              //       this.curData = params.row;
              //       this.verifyData(3)
              //     }
              //   }
              // }, '审核成功') : '',
              // params.row.status == 1 ? h('Button', {
              //   props: {
              //     type: 'primary',
              //     size: 'small',
              //   },
              //   on: {
              //     click: () => {
              //       this.curData = params.row;
              //       this.verifyModal = true;
              //     }
              //   }
              // }, '审核失败') : '',
              // params.row.status == 3 ? h('Button', {
              //   props: {
              //     type: 'primary',
              //     size: 'small',
              //   },
              //   style: {
              //     marginRight: '5px',
              //     marginBottom:"5px"
              //   },
              //   on: {
              //     click: () => {
              //       this.curData = params.row;
              //       this.formValidate.name = '';
              //       this.formValidate.appQr = '';
              //       this.publishModal = true;
              //     }
              //   }
              // }, '发布成功') : '',
            ]);
          }
        },
      ],
      dataList: [],
      loading:false,
      total:0,
      page:1,
      pageSize:10,
      keyword:'',
      statusObj:{},
      verifyModal:false,
      verifyReason:'',
      curData:{},
      formValidate:{
        name:'',
        appQr:'',
      },
      ruleValidate:{
        name: [
          { required: true, message: '请输入小程序名称', trigger: 'blur' }
        ],
        appQr: [
          { required: true, message: '请上传二维码', trigger: 'change' }
        ],
      },
      publishModal:false,
      statusArr:[],
      miniProStatus:"-1"
    }
  },
  watch:{
    '$store.state.user.userInfo.id':function(newV,oldV){
      if(newV){
        this.getList();
      }
    }
  },
  created(){
    this.getList();
  },
  methods:{
    // 编辑
    edit(row){
      console.log("当前状态",row.status);
      this.$router.push({
        path:"/dataset/wxmini",
        query:{
          xcx_id:row.id
        }
      });
    },
    fileSuccess(response,file, fileList){  //上传文件成功
      // console.log(response.data.info.upload_path,'dddd')
      this.formValidate.appQr = response.data.info.upload_path;
    },
    okPublishModal(){
      this.$refs['formValidate'].validate((valid) => {
        if(valid){
          this.verifyData(4)
        }
      })

    },
    cancelPublishModal(){
      this.publishModal = false;
    },
    changeKeyword(){
      this.page = 1;
      this.getList();
    },
    getList(){
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        keyword:this.keyword,
        user_id:this.$store.state.user.userInfo.id,
        status:this.miniProStatus
      };
      this.loading = true;
      this.api.wxmini.thirdpartyList(params).then((res)=>{
        this.loading = false;
        this.dataList = res.list;
        this.total = Number(res.count);
        this.statusObj = res.statuses;
        const arr=[];
        Object.keys(this.statusObj).forEach(key=>{
          arr.push({
            id:key,
            name:this.statusObj[key]
          });
        });
        arr.unshift({
          id:"-1",
          name:"全部"
        });
        this.statusArr=arr;
      }).catch(()=>{
        this.loading = false;
      })
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.page = 1;
      this.pageSize = pageSize;
      this.getList();
    },
    okVerifyModal(){
      this.verifyData(2)
    },
    cancelVerifyModal(){
      this.verifyReason = '';
      this.verifyModal = false;
    },
    verifyData(status){
      let params = {
        id:this.curData.id,
        verify_status:status,
      };
      if(this.verifyReason){
        params.verify_reason = this.verifyReason;
      }
      if(status == 4){
        params.name = this.formValidate.name;
        params.app_qr = this.formValidate.appQr;
      }
      this.api.wxmini.thirdpartyVerify(params).then((res)=>{
        this.getList();
        if(this.verifyReason){
          this.verifyReason = '';
          this.verifyModal = false;
        }
        if(status == 4){
          this.publishModal = false;
        }
      })
    },
    statusChange(id){
      this.miniProStatus=id;
      this.getList();
    }
  }
}
</script>

<style scoped lang="scss">
.manage-list{
  margin: 20px;
  padding:20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
  border-radius: 4px;

  .manage-list-top{
    display: inline-block;
    .manage-list-top-cont{
      border: 1px solid #dcdee2;
      border-radius: 4px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 38px;
      line-height: 38px;
      >div{
        padding: 0 20px;
        border-right: 1px solid #dcdee2;
      }
      >div:last-child{
        border-right: none;
      }
      .active{
        color:#FFFFFF;
        background-color:#2d8cf0 ;
      }
    }
  }
  .search{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .list-search-r-input{
      width: 300px;
    }
    .search-item{
      display: flex;
      align-items: center;
      font-size: 16px;
      >p{
        font-weight: bold;
      }
      .status-list{
        display: flex;
        flex-wrap: wrap;
        .status-item{
          padding: 5px 10px;
          border-radius: 4px;
          cursor: pointer;
        }
        .active-item{
          background-color: #2d8cf0;
          color: white;
        }
      }
    }
  }
  .table{
    margin-top: 20px;
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }
}
.verify-reason{
  display: flex;
  justify-content: flex-start;
  >p{
    width: 100px;
  }
  .verify-reason-input{
    flex: 1;
  }
}
.wx-code{
  width: 123px;
  height: 123px;
}
</style>
